// const SERVER_IP = process.env.REACT_APP_SERVER_IP;

// const development = {
//     apiHost: `http://${SERVER_IP}:6060`,
//     socketHost: `http://${SERVER_IP}:3838`,
//     API_PREFIX: "/api/v1/",
// };

// const production = {
//     apiHost: "https://api.jobbook.com",
//     socketHost: "https://___.com",
//     API_PREFIX: "/api/v1/",
// };


export const API_URL = 'http://localhost:3000'; //local
export const API_PREFIX = "/api/v1/";
// export const map_api_key = "AIzaSyD67LS8acDfx3sKM5HimzFP_MQdsMM9gNk";


